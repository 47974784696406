/* eslint-disable @typescript-eslint/no-use-before-define, @typescript-eslint/ban-ts-ignore, @typescript-eslint/no-unnecessary-type-assertion */
import { createStore, createEvent, } from 'effector';
import { using, h, spec } from 'effector-dom';
import { TreeView } from './tree-view';
const storeAdd = createEvent();
const $stores = createStore({ map: new Map() });
$stores.on(storeAdd, ({ map }, descriptor) => {
    var _a, _b;
    const name = (_a = descriptor.name, (_a !== null && _a !== void 0 ? _a : createName(descriptor.store)));
    map.set(name, {
        store: descriptor.store,
        mapped: (_b = descriptor.mapped, (_b !== null && _b !== void 0 ? _b : false)),
    });
    return { map };
});
function createName(unit) {
    return unit.compositeName.path.join('/');
}
export function addStore(store, opts = {}) {
    storeAdd(Object.assign({ store }, opts));
}
export const addEvent = createEvent();
export function createInspector(options = {}) {
    const root = document.createElement('div');
    root.classList.add('effector-inspector');
    document.body.append(root);
    Root(root, options);
    return { root };
}
function Root(root, options) {
    var _a;
    const $showInspector = createStore((_a = options.visible, (_a !== null && _a !== void 0 ? _a : false)));
    const togglePressed = createEvent();
    const KeyB = 2;
    console.info('%c[effector-inspector] %cPress %cCTRL+B %cto open Inspector', 'color: gray; font-size: 1rem;', 'color: currentColor; font-size: 1rem;', 'color: deepskyblue; font-family: monospace; font-size: 1rem;', 'color: currentColor; font-size: 1rem;');
    $showInspector.on(togglePressed, (show) => !show);
    document.addEventListener('keypress', (event) => {
        const ev = event;
        if (ev.keyCode === KeyB && ev.ctrlKey) {
            togglePressed();
        }
    });
    using(root, () => {
        h('div', () => {
            spec({ style: styles.root, visible: $showInspector });
            Stores(options);
        });
    });
}
const trimDomain = (domainName, name) => domainName ? name.replace(domainName + '/', '') : name;
function Stores(options) {
    h('div', { style: styles.sectionHead, text: 'Stores' });
    h('div', () => {
        spec({ style: styles.storesTable });
        TreeView($stores.map(({ map }) => map), options);
    });
}
const styles = {
    root: {
        backgroundColor: 'white',
        borderRadius: '0.5rem',
        bottom: '3rem',
        boxShadow: '0 14.5px 5.2px -10px rgba(0,0,0,0.038), 0 23.9px 16.6px -10px rgba(0,0,0,0.057), 0 64px 118px -10px rgba(0,0,0,0.08)',
        display: 'flex',
        flexDirection: 'column',
        left: '3rem',
        overflowX: 'auto',
        position: 'fixed',
        top: '3rem',
        userSelect: 'none',
        width: '26rem',
        zIndex: 1000,
    },
    sectionHead: {
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0,0,0,0.08)',
        display: 'flex',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        left: 0,
        lineHeight: '2rem',
        padding: '0.5rem 1rem',
        position: 'sticky',
        right: 0,
        top: 0,
    },
    storesTable: {
        display: 'flex',
        flexDirection: 'column',
    },
    store: {
        display: 'table-row',
        fontSize: '1rem',
    },
    storeName: {
        margin: 0,
        display: 'table-cell',
        padding: '0.5rem 1rem',
    },
    storeValue: {
        margin: 0,
        display: 'table-cell',
        padding: '0.5rem 1rem',
    },
};
