import set from 'set-value';
import { createName } from './lib';
/* eslint-disable @typescript-eslint/ban-ts-ignore, @typescript-eslint/no-explicit-any */
const reduxDevTools = 
// @ts-ignore
typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__;
const rootState = {};
function setState(name, value) {
    set(rootState, name.replace(/\//g, '.'), value);
}
export function eventCalled(name, payload) {
    if (reduxDevTools) {
        reduxDevTools.send({ type: `${name} (event)`, payload }, rootState);
    }
}
export function storeAdded(store) {
    const name = createName(store.compositeName);
    setState(name, store.defaultState);
}
export function storeUpdated(name, value) {
    setState(name, value);
    if (reduxDevTools) {
        reduxDevTools.send({ type: `${name} (store updated)`, value }, rootState);
    }
}
function effectUpdateState(name, effect) {
    setState(name, {
        inFlight: effect.inFlight.getState(),
        pending: effect.pending.getState(),
    });
}
export function effectAdded(name, effect) {
    effectUpdateState(name, effect);
}
export function effectCalled(name, effect, parameters) {
    effectUpdateState(name, effect);
    if (reduxDevTools) {
        reduxDevTools.send({ type: `${name} (effect called)`, params: parameters }, rootState);
    }
}
export function effectDone(name, effect, parameters, result) {
    effectUpdateState(name, effect);
    if (reduxDevTools) {
        reduxDevTools.send({ type: `${name}.done (effect finished)`, params: parameters, result }, rootState);
    }
}
export function effectFail(name, effect, parameters, error) {
    effectUpdateState(name, effect);
    if (reduxDevTools) {
        reduxDevTools.send({ type: `${name}.fail (effect finished)`, params: parameters, error }, rootState);
    }
}
