let inspector = null;
try {
    inspector = require('effector-inspector');
}
catch (_) {
    /** Nothing here */
}
export function eventAdded(event) {
    /** Nothing here */
}
export function storeAdded(store, mapped = false) {
    var _a;
    (_a = inspector) === null || _a === void 0 ? void 0 : _a.addStore(store, { mapped });
}
